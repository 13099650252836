.bonus_wrapper{
    justify-content: end;
    .bonus_container{
        margin-right: 70px;
        img{
            height: 130% !important;
        }
    }
}
.btn.bonus {
    margin-top: 15px;
}
.search_icon{
    @include recolor(#ea0f14)
}
.search_icon:hover{
    @include recolor(#fac001)
}
.jackpot.name{
    color: red;
}
.jackpot.value{
    color: #fcc201;
}

.lp-bg {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    z-index: inherit;
    top: 10%;
    width: 100vw;
    height: 75vh;
    animation: flare;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.bonus_slider{
    background-color: black;
    @media screen and (max-width: 600px) {
        .bonus_wrapper .bonus_container img {
            height: 100% !important;
        }
        .carousel-item{
            width: 100% !important;
        }
        .carousel-initialized{
            padding: 0 !important;
        }
        .bonus_wrapper{
            justify-content: center;
            background-size: cover !important;
            background-position-x: -350px !important;
            .bonus_container{
                margin-right: 0px;
            }
        }
        .btn.bonus {
            margin-top:0px;
            height: initial;
        }

    }
}